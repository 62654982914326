import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";


function MiladeAkbar() {
  return (
    <div className='contain'>
        <div className="page-header">
          <span className="backBtn" onClick={()=> window.history.back()}>
            <MdArrowBackIosNew />
          </span>
          <div className="page-header__content">
            <h2 className="pagetitle">Milade Akbar</h2>
          </div>
       </div>
       <div className="contentSection-card">
         <iframe src="https://archive.org/details/in.ernet.dli.2015.472508/page/n6/mode/2up?view=theater" title="W3Schools Free Online Web Tutorials" height="500px" width="100%">
        </iframe>
        <div className='miladeakbar__hindiSection'>
          <div className="contentSection-card" style={{display:'none'}}>
              <p className="contentSection_title--content">
              किस से तू हैं हय्य कब्रिया हो रक़म <br />
              सर-क़लम हैं यहाँ क़लम के क़लम
              </p>
              <p className="contentSection_title--content">
              </p>
              <p className="contentSection_title--content">
              फर्श से ता ब'आलम बाला <br />
              ग़ुल है सबहान रब्बी अल-आला
              </p>
              <p className="contentSection_title--content">
              रूह-क़ालब में डालने वाला <br />
              दाम-आम से निकालने वाला
              </p>
              <p className="contentSection_title--content">
              जंगलों में वह बेकसूं का रफ़ीक़ <br />
              मुश्किलों में वह बेबसूं का शफ़ीक़
              </p>
              <p className="contentSection_title--content">
              ग़मज़दों का वली, ग़रीब का यार <br />
              सबब का बिगढ़ी उड़ी में खेयो निहार
              </p>
              <p className="contentSection_title--content">
              ख़ल्क करने लगी जो बे-अदबी <br />
              भेजा उसने मुहम्मद अरबी
              </p>
              <p className="contentSection_title--content">
              नूर-हक से हुआ जहाँ मआमूर <br />
              ज़ुल्मत-कुफ़्र हो गई काफ़िर
              </p>
              <p className="contentSection_title--content">
              रिश्ता-ए इश्क़-हक से जोड़ दिया <br />
              कलमा पढ़कर बुतों को तोड़ दिया
              </p>

              <p className="contentSection_title--content">
              आ गए राह पर जो थे गुमराह <br /> 
              पढ़ लिया ला इलाहा इल्लाल्लाह
                </p>

                <p className="contentSection_title--content">
                ढांका रहमत के शामियाने में <br />
                दीन फैला दिया ज़माने में
                </p>
                <p className="contentSection_title--content">
                  वाह आन मुहम्मद अरबी <br />
                  वाह शान मुहम्मद अरबी
                </p>
                <p className="contentSection_title--content">
                उसकी महफ़िल की धूमधाम है आज <br />
                क्या फ़रिश्तों का अज़दहाम है आज
                </p>
                <p className="contentSection_title--content">
                पढ़ते सल्लू अलैह यहाँ आओ <br />
                शरबत-ए वुस्ल नोश फरमाओ
                </p>
                <p className="contentSection_title--content">
                बैठो आ कर यहाँ अदब के साथ <br />
                दिल लगाओ शाह अरब के साथ
                </p>
                <p className="contentSection_title--content">
                जहाँ अकबर दरूद होता है <br />
                रहमतों का वुरूद होता है!
                </p>

              <div>
            





              </div>
          </div>
        </div>
       </div>
      
    </div>
  )
}

export default MiladeAkbar





