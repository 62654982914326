import React, { Component } from "react";
import Slider from "react-slick";
import './SliderSection.css';
import slide1 from '../Images/slides/slide1.png';
import slide2 from '../Images/slides/slide2.png';
import slide3 from '../Images/slides/slide3.png';
import slide4 from '../Images/slides/slide4.png';
import slide5 from '../Images/slides/slide5.png';
import slide6 from '../Images/slides/slide6.png';
import slide7 from '../Images/slides/slide7.png';
import slide8 from '../Images/slides/slide8.png';
import slide9 from '../Images/slides/slide9.png';
import { IoIosArrowBack, IoIosArrowForward  } from "react-icons/io";




export default class SliderSection extends Component {
  render() {
    const settings = {
    //   dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow:<IoIosArrowBack />,
      nextArrow :  <IoIosArrowForward /> ,

      
    };
    return (
      <div className="SliderSection">
        <Slider {...settings}>
          <div>
            <img src={slide1} alt="slide1" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide2} alt="slide2" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide3} alt="slide3" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide4} alt="slide4" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide5} alt="slide5" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide6} alt="slide6" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide7} alt="slide7" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide8} alt="slide8" width="100%" height="100%" />
          </div>
          <div>
          <img src={slide9} alt="slide9" width="100%" height="100%" />
          </div>
        </Slider>
      </div>
    );
  }
}