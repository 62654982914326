import React, { useState } from "react";
import logo from "../Images/logo.webp";
import './TopHeader.css';
import { GiHamburgerMenu } from "react-icons/gi";
import { RiMoonLine, RiSunLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const TopHeader = ({setClassName,darkMode})=> {
    const [isDarkMode, setIsDarkMode] = useState(false);

  const darkModeIcon = () => {
    setIsDarkMode(!isDarkMode);
  };
  const handleDarkModeClick = () => {
    darkMode();
    darkModeIcon();
  };
    return (
       <>
        <header className="header-section">
        <a href="#main" class="skip">Skip to main content</a>
            <Link to='/'>
            <div className="header-section__item">
                <img src={logo} className="logo"  alt="logo" title="logo" width={110} height={79} />
            </div>
            </Link>
            <div className="header-section__item2">
                <div className="header-section__hadees">
                    <p className="urduHadees">
                        طَلَبُ الْعِلْمِ فَرِيضَةٌ عَلَى كُلِّ مُسْلِمٍ 
                        </p>
                 </div>
                <span className="moonIcon" onClick={handleDarkModeClick}>
                 {isDarkMode ? <RiSunLine /> : <RiMoonLine />}
                </span>
                <span className="hamburgerIcon" onClick={setClassName}><GiHamburgerMenu /></span>
                <input type="text" placeholder="Serch here" className="searchInput"/>
            </div>
        </header>
       </>
    )
}

export default TopHeader;
