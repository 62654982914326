import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdArrowBackIosNew } from "react-icons/md";


function SelectMonthTT() {
   const navigate = useNavigate();
  return (
    <div className='selectmonthsection contain' >
       <div className="page-header">
          <span className="backBtn" onClick={()=> window.history.back()}>
            <MdArrowBackIosNew />
          </span>
          <div className="page-header__content">
            <h2 className="pagetitle">Select Month</h2>
          </div>
       </div>
       <div className='contentSection-card monthsection'>
         <div className='monthsection__item' onClick={()=>navigate('../januarySehriIftaar') }>
            <span>January</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../februarySehriIftaar') }>
            <span>February</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../marchSehriIftaar') }>
            <span>March</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../aprilSehriIftaar') }>
            <span>April</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../maySehriIftaar')}>
            <span>May</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../juneSehriIftaar')}>
            <span>June</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../julySehriIftaar')}>
            <span>July</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../augustSehriIftaar')}>
            <span>August</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../septemberSehriIftaar')}>
            <span>September</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../octoberSehriIftaar')}>
            <span>October</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../novemberSehriIftaar')}>
            <span>November</span>
         </div>
         <div className='monthsection__item' onClick={()=>navigate('../decemberSehriIftaar')}>
            <span>December</span>
         </div>
       </div>
    </div>
  )
}

export default SelectMonthTT