import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import { BsCloudDownload } from "react-icons/bs";
import RamzanCal1 from "../Images/ramzanCal1.png";
import RamzanCal2 from "../Images/ramzanCal2.png";
import RamzanCal3 from "../Images/ramzanCal3.png";
import RamzanCal4 from "../Images/ramzanCal4.png";
import RamzanCal5 from "../Images/ramzanCal5.png";
import RamzanCal6 from "../Images/ramzanCal6.png";
import RamzanCal7 from "../Images/ramzanCal7.png";
import RamzanCal8 from "../Images/ramzanCal8.png";
import RamzanCal9 from "../Images/ramzanCal9.png";
import RamzanCal10 from "../Images/ramzanCal10.png";
import RamzanCal11 from "../Images/_Ramzan-ul-Mubarak_11.png";
import RamzanCal12 from "../Images/_Ramzan-ul-Mubarak_12.png";
import RamzanCal13 from "../Images/_Ramzan-ul-Mubarak_13.png";
import RamzanCal14 from "../Images/_Ramzan-ul-Mubarak_14.png";
import RamzanCal15 from "../Images/_Ramzan-ul-Mubarak_15.png";
import RamzanCal16 from "../Images/_Ramzan-ul-Mubarak_16.png";
import RamzanCal17 from "../Images/_Ramzan-ul-Mubarak_17.png";
import RamzanCal18 from "../Images/_Ramzan-ul-Mubarak_18.png";
import RamzanCal19 from "../Images/_Ramzan-ul-Mubarak_19.png";
import RamzanCal20 from "../Images/_Ramzan-ul-Mubarak_20.png";
import RamzanCal21 from "../Images/_Ramzan-ul-Mubarak_21.png";
import RamzanCal22 from "../Images/_Ramzan-ul-Mubarak_22.png";
import RamzanCal23 from "../Images/_Ramzan-ul-Mubarak_23.png";
import RamzanCal24 from "../Images/_Ramzan-ul-Mubarak_24.png";
import RamzanCal25 from "../Images/_Ramzan-ul-Mubarak_25.png";
import RamzanCal26 from "../Images/_Ramzan-ul-Mubarak_26.png";
import RamzanCal27 from "../Images/_Ramzan-ul-Mubarak_27.png";
import RamzanCal28 from "../Images/_Ramzan-ul-Mubarak_28.png";
import RamzanCal29 from "../Images/_Ramzan-ul-Mubarak_29.png";
import RamzanCal30 from "../Images/_Ramzan-ul-Mubarak_30.png";








import './RamzanCalendar.css';



function RamzanCalendar() {
  return (
    <div className='contain'>
        <div className="page-header">
          <span className="backBtn" onClick={()=> window.history.back()}>
            <MdArrowBackIosNew />
          </span>
          <div className="page-header__content">
            <h2 className="pagetitle">Ramzan Calendar 2024</h2>
          </div>
       </div>
       <div className="contentSection">
       <div className='calendar-section'>
        <a href={RamzanCal1} download> 
         <img title='ramzanimg' src={RamzanCal1} alt='ramzanCal1' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal2} download> 
         <img title='ramzanimg' src={RamzanCal2} alt='ramzanCal2' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal3} download> 
         <img title='ramzanimg' src={RamzanCal3} alt='ramzanCal3' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal4} download> 
         <img title='ramzanimg' src={RamzanCal4} alt='ramzanCal4' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal5} download> 
         <img loading='lazy' title='ramzanimg' src={RamzanCal5} alt='ramzanCal5' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal6} download> 
         <img loading='lazy' title='ramzanimg' src={RamzanCal6} alt='ramzanCal6' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal7} download> 
         <img loading='lazy' title='ramzanimg' src={RamzanCal7} alt='ramzanCal7' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal8} download> 
         <img loading='lazy' title='ramzanimg' src={RamzanCal8} alt='ramzanCal8' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
       
         <a href={RamzanCal9} download> 
         <img loading='lazy' title='ramzanimg' src={RamzanCal9} alt='ramzanCal9' />
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal10} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal10} alt='ramzanCal10' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>


         <a href={RamzanCal11} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal11} alt='ramzanCal11' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal12} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal12} alt='ramzanCal12' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal13} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal13} alt='ramzanCal13' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>  
         <a href={RamzanCal14} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal14} alt='ramzanCal14' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal15} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal15} alt='ramzanCal15' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>  
         <a href={RamzanCal16} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal16} alt='ramzanCal16' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>  
         <a href={RamzanCal17} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal17} alt='ramzanCal17' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal18} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal18} alt='ramzanCal18' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal19} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal19} alt='ramzanCal19' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal20} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal20} alt='ramzanCal20' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal21} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal21} alt='ramzanCal21' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal22} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal22} alt='ramzanCal22' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a> 
          <a href={RamzanCal23} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal23} alt='RamzanCal23' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>

         <a href={RamzanCal24} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal24} alt='ramzanCal24' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal25} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal25} alt='ramzanCal25' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal26} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal26} alt='ramzanCal26' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal27} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal27} alt='ramzanCal27' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal28} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal28} alt='ramzanCal28' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal29} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal29} alt='ramzanCal29' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         <a href={RamzanCal30} download>    
         <img loading='lazy' title='ramzanimg' src={RamzanCal30} alt='ramzanCal30' /> 
         <span className='download-text'>Download <BsCloudDownload /></span>
         </a>
         </div>
         </div>
    </div>
  )
}

export default RamzanCalendar