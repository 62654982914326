import React from "react";
import TopHeader from "../Components/TopHeader/TopHeader";
import MainLayout from "../Components/MainLayout/MainLayout";
import Footer from "../Components/Footer/Footer";

const Layout = ({isClass, setClassName, darkMode}) => {
    return(
        <>
          <TopHeader setClassName={setClassName} darkMode={darkMode} />
          <MainLayout isClass={isClass} setClassName={setClassName} />
          <Footer />
        </>
    )
}

export default Layout;          