import logo from './logo.svg';
import './App.css';
import Layout from './Layout/Layout';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  const[isClass, setClass] = useState();
  const[isActive, setActive] = useState();

  
  // useEffect(() => {
  //   document.body.classList.toggle('active', isClass)},[isClass]);
  const setClassName = () =>{
    if(window.innerWidth < 993){
      setClass(!isClass);
    }
  }

  const darkMode = ()=>{
    setActive(!isActive);
  }

  return (
    <div className={`container ${isClass ? 'nav-slided' : ''} ${isActive ? 'darkMode' : ''}` }>
      <BrowserRouter>
         <Layout setClassName={setClassName}  isClass={isClass} darkMode={darkMode} />
      </BrowserRouter>
      
    </div>
  );
}
export default App;